import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from '../Home/v2/StyledComponents';
import { colors, margins, fontSizes, mobileThresholdPixels, Total, TotalRow, Highlight }
  from '../styledComponents';
import featureSwitch from '../../services/featureSwitch';

const Container = styled.div`
  padding: 0px;
  margin: ${margins.s} ${margins.m};
  border: ${colors.navy} 1px solid;
  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${margins.s} 0px;
  }
`;

const Title = styled.h4`
  margin: ${margins.s};
  text-align: center;
  font-size: ${fontSizes.l};
  color: ${colors.navy};
  font-weight: 300;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${margins.s} ${margins.xs} ${margins.xs};
    padding: ${margins.s} 0 0 0;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
  }
`;

const StyledButton = styled(Button)`
  margin: ${margins.m};
  width: ${({ wide }) => wide ? '250' : '200'}px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${margins.s};
  }
`;

const ButtonContainer2 = styled.div`
  margin: ${margins.m} ${margins.s};
  display: initial;
`;

const SlotsContainer = styled.div`
  margin: ${margins.m};
`;

const ButtonRow = styled.div`
  flex-direction: row;
  justify-content: center;
  text-align: center;
  margin: ${margins.m} 0px;
`;

const ClothName = styled.p`
  text-align: center;
  color: ${colors.navy};
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
`;

const AlterationName = styled.p`
  text-align: center;
  color: ${colors.navy};
  font-size: 16px;
  line-height: 20px;
`;

const ErrorText = styled.p`
  text-align: center;
  color: ${colors.error};
  font-size: 16px;
  line-height: 20px;
  max-width: 320px;
  margin: ${margins.m} auto;
`;

function renderClothesDetails(clothes, pricingInverse, promoCode) {
  if (!clothes || clothes.length === 0) {
    if (promoCode && promoCode.brand) {
      return (
        <ErrorText>
          Merci de bien vouloir renseigner le nombre de vêtements dans l’étape 1 lorsque la retouche est offerte
        </ErrorText>
      );
    }
    return null;
  }
  return (
    <div>
      {clothes.map(cloth => (
        <div key={cloth.name}>
          <ClothName>
            {cloth.clothName}
          </ClothName>
          {cloth.alterations && Object.keys(cloth.alterations)
            .map(alterationId => (cloth.alterations[alterationId] > 0) && (
              <AlterationName key={alterationId}>
                {pricingInverse[alterationId].name}
              </AlterationName>
            ))}
        </div>
      ))}
    </div>
  );
}

const OrderSummary = ({ promoCode, deliveryFee, total, totalPaid,
  children, slots, addPromoCode, removePromoCode, hasPromoCodeBeenSelected,
  minOrderAmount, clothes, pricingInverse,
}) => (
  <div>
    {featureSwitch('infos_codePromoButton') &&
      <ButtonContainer>
        <StyledButton
          secondary
          navy={promoCode && promoCode.value}
          onClick={() => addPromoCode(featureSwitch('infos_codePromoButton'))}
          wide={featureSwitch('infos_codePromoButtonLabel').length > 32}
        >{featureSwitch('infos_codePromoButtonLabel')}</StyledButton>
        <StyledButton
          secondary
          navy={hasPromoCodeBeenSelected && !(promoCode && promoCode.value)}
          onClick={() => removePromoCode()}
          wide={featureSwitch('infos_codePromoButtonLabel').length > 32}
        >à la charge du client</StyledButton>
      </ButtonContainer>
    }
    {featureSwitch('infos_codePromoButtons') &&
      <ButtonRow>
        {featureSwitch('infos_codePromoButtons').map((promoCodeIt) => {
          const isSelected = promoCode && promoCode.value === promoCodeIt.value;
          return (
            <ButtonContainer2 key={promoCodeIt.code}>
              <Button
                small
                navy={isSelected}
                onClick={() => isSelected ? removePromoCode() : addPromoCode(promoCodeIt.code)}
              >Remise de {promoCodeIt.value}€</Button>
            </ButtonContainer2>
          );
        })}
      </ButtonRow>
    }
    <Container>
      <Title>Les retouches de votre client</Title>
      {renderClothesDetails(clothes, pricingInverse, promoCode)}
      <Total>
        {promoCode && promoCode.value && featureSwitch('infos_codePromoButton') &&
          <TotalRow bold>
          Prise en charge {featureSwitch('brandName')} : {promoCode.value}{promoCode.unit === 'EUROS' ? '€' : '%'}
          </TotalRow>
        }
        {featureSwitch('order_thirdPersonLabels') ?
          'Les disponibilités de mon client :' :
          'Vos disponibilités :'
        }
        <SlotsContainer>
          {slots.map((slot) => {
            const rdvDate = slot.begin.format('D MMMM');
            const rdvBegin = slot.begin.format('H[h]mm');
            const rdvEnd = slot.end.format('H[h]mm');
            return (
              <Highlight key={`${rdvDate}${rdvBegin}${rdvEnd}`}>
                {`le ${rdvDate} entre ${rdvBegin} et ${rdvEnd}`}<br />
              </Highlight>
            );
          })}
        </SlotsContainer>
        {(total > 0) ?
          <TotalRow>Total des retouches : {total}€</TotalRow> :
          <TotalRow>Total des retouches : devis sur place</TotalRow>
        }
        {(minOrderAmount > 0) ?
          <TotalRow>Minimum de commande : {minOrderAmount}€</TotalRow> :
          null
        }
        {promoCode && promoCode.value && !featureSwitch('infos_codePromoButton') &&
          <TotalRow>
            Code promo : -{promoCode.value}{promoCode.unit === 'EUROS' ? '€' : '%'}
          </TotalRow>
        }
        <TotalRow>Frais de déplacement : {deliveryFee}€</TotalRow>
        <TotalRow bold>Montant total : {totalPaid}€</TotalRow>

        {children}
      </Total>
    </Container>
  </div>
);

OrderSummary.propTypes = {
  promoCode: PropTypes.shape({}),
  deliveryFee: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  totalPaid: PropTypes.number.isRequired,
  children: PropTypes.node,
  slots: PropTypes.arrayOf(PropTypes.shape({})),
  addPromoCode: PropTypes.func,
  removePromoCode: PropTypes.func,
  hasPromoCodeBeenSelected: PropTypes.bool,
  minOrderAmount: PropTypes.number.isRequired,
  clothes: PropTypes.arrayOf(PropTypes.shape({})),
  pricingInverse: PropTypes.shape({}).isRequired,
};

OrderSummary.defaultProps = {
  promoCode: null,
  children: null,
  slots: [],
  addPromoCode() {},
  removePromoCode() {},
  hasPromoCodeBeenSelected: false,
  clothes: [],
};

export default OrderSummary;
