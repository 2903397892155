import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { mobileThresholdPixels, Input, Center, ErrorText } from '../styledComponents';
import { Button } from '../Home/v2/StyledComponents';

const Container = styled.div``;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const StyledButton = styled(Button)`
  margin: 0px 0px 0px 10px;

  @media (max-width: ${mobileThresholdPixels}) {
    min-width: unset;
  }
`;

const P = styled.p`
  text-align: center;
  font-weight: 300;
`;

const PromoCodeMessage = styled.p`
  font-size: 18px;
  margin: 0px;
`;

const B = styled.span`
  font-weight: normal;
`;

class PromoCodeField extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const { promoCode, errorPromoCode, checkPromoCode } = this.props;
    return (
      <Container>
        <Row>
          <Input
            half center
            type="text" placeholder="Code promo" value={this.state.promoCode}
            onChange={e => this.setState({ promoCode: e.target.value })}
            onBlur={() => checkPromoCode(this.state.promoCode)}
          />
          <StyledButton>Valider</StyledButton>
        </Row>
        {promoCode && promoCode.code &&
          <Center>
            {promoCode.value ?
              <P>
                <PromoCodeMessage>
                  {promoCode.name} vous offre <B>{promoCode.value}{promoCode.unit === 'EUROS' ? '€' : '%'}</B> sur
                cette commande.</PromoCodeMessage>
                {promoCode.brand && promoCode.brand.name &&
                  '(code valide uniquement sur les vêtements sélectionnés)'
                }
              </P>
              : `Le code ${promoCode.name} a bien été pris en compte pour cette commande.`
            }
          </Center>
        }
        {errorPromoCode &&
          <ErrorText>Code non reconnu</ErrorText>
        }
      </Container>
    );
  }
}


PromoCodeField.propTypes = {
  promoCode: PropTypes.shape({}),
  checkPromoCode: PropTypes.func.isRequired,
  errorPromoCode: PropTypes.bool,
};

PromoCodeField.defaultProps = {
  promoCode: null,
  errorPromoCode: false,
};

export default PromoCodeField;
