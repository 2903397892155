import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import mangopayImg from '../../assets/mangopay.png';
import { Button } from '../Home/v2/StyledComponents';
import { mobileThresholdPixels, Center, SmallText, Link } from '../styledComponents';
import featureSwitch from '../../services/featureSwitch';

const Img = styled.img`
  object-fit: contain;
  width: 70%;
  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
  }
`;

const StyledButton = styled(Button)`
  margin: 20px;
`;

const OrderFooter = ({ isValid, submit }) => (<div>
  <Center>
    <StyledButton
      isNotValid={!isValid}
      onClick={submit}
    >{featureSwitch('infos_validateOrderWording') ? 'valider ma commande' : 'valider mon rdv'}</StyledButton>
  </Center>
  <SmallText>
    {'En validant, vous acceptez les '}
    <Link href="#">
      conditions générales
    </Link>
    {' de vente de Tilli'}
  </SmallText>
  <Center>
    <Img src={mangopayImg} alt="MangoPay notre partenaire de paiement" />
  </Center>
</div>);

OrderFooter.propTypes = {
  isValid: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired,
};

export default OrderFooter;
